import React, { useState, useEffect } from 'react';
import ErpResultDetail from '../components/ErpResultDetail';

const Page2 = () => {
    const [erpResultdata, setErpResultdata] = useState([]);
    const [selectedSituationId, setSelectedSituationId] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedErpResult, setSelectedErpResult] = useState(null);
    const [uniqueSituations, setUniqueSituations] = useState([]);
    const [uniqueModels, setUniqueModels] = useState([]);

    const handleDropdownChange = (newSituationId, newModel) => {
        let foundErpResult = erpResultdata.find(erpResult => (
            erpResult.situation_id === newSituationId &&
            erpResult.model_id === newModel
        ));

        setSelectedErpResult(foundErpResult);
    };

    useEffect(() => {
        fetch('/erp_scoring_results.jsonl')
            .then((response) => response.text())
            .then((data) => {
                const lines = data.trim().split('\n');
                const parsedData = lines.map((line) => JSON.parse(line));
                setErpResultdata(parsedData);
                setSelectedSituationId(parsedData[0].situation_id);
                setSelectedModel(parsedData[0].model_id);
                const situations = [...new Set(parsedData.map(item => item.situation_id))];
                // 数値としてソートして、再び文字列に変換する
                const sortedSituations = situations.sort((a, b) => parseInt(a) - parseInt(b));

                setUniqueSituations(sortedSituations);
                const models = [...new Set(parsedData.map(item => item.model_id))];

                setUniqueSituations(situations);
                setUniqueModels(models);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // フェッチしたデータに基づいて、デフォルトの値を設定する
    useEffect(() => {
        if (erpResultdata.length > 0) {
            handleDropdownChange(uniqueSituations[0], uniqueModels[0]);
        }
    }, [erpResultdata, uniqueSituations, uniqueModels]);

    return (
        <div className="dark:bg-gray-800 dark:text-white min-h-screen p-6">
            <div className="mb-4 flex space-x-4">

                <div className="flex flex-col">
                    <label className="text-gray-400 mb-1">situation_id</label>
                    <select value={selectedSituationId} onChange={(e) => {
                        setSelectedSituationId(e.target.value);
                        handleDropdownChange(e.target.value, selectedModel);
                    }} className="dark:bg-gray-700 p-2 border dark:border-gray-600 rounded">
                        {uniqueSituations.map(id => (
                            <option key={id} value={id}>{id}</option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col">
                    <label className="text-gray-400 mb-1">model_id</label>
                    <select value={selectedModel} onChange={(e) => {
                        setSelectedModel(e.target.value);
                        handleDropdownChange(selectedSituationId, e.target.value);
                    }} className="dark:bg-gray-700 p-2 border dark:border-gray-600 rounded">
                        {uniqueModels.map(id => (
                            <option key={id} value={id}>{id}</option>
                        ))}
                    </select>
                </div>

            </div>

            {selectedErpResult ? <ErpResultDetail scoring_result={selectedErpResult} /> : <p className="text-gray-400">評価結果が見つかりません</p>}
        </div>
    );
};

export default Page2;
