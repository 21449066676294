import React from 'react';

const MatchDetail = ({ match }) => {
  const imageUrl = `/images/${match.situation_id}.png`; // 画像のパスを指定

  return (
    <div className="rounded-lg overflow-hidden shadow-lg dark:bg-gray-700 p-4">
      <div className="flex mb-4">
        <div className="mr-6">
          <img src={imageUrl} alt="Situation Image" className="" /> {/* 画像の表示部分 */}
        </div>
        <div>
          <div className="mb-4">
            <strong className="text-gray-400">Character:</strong> {match.character}
          </div>
          <div className="mb-4">
            <strong className="text-gray-400">Context:</strong> {match.context}
          </div>
        </div>
      </div>
      <div className="flex mb-4 space-x-6">
        <div>
          <strong className="text-gray-400">{match.model_id_A}:</strong> {match.response_A}
        </div>
        <div>
          <strong className="text-gray-400">{match.model_id_B}:</strong> {match.response_B}
        </div>
      </div>
      <div className="mb-4">
        <strong className="text-gray-400">Winner:</strong> {match.winner}
      </div>
      <div>
        <strong className="text-gray-400">Review:</strong> {match.review}
      </div>
    </div>
  );
};

export default MatchDetail;