import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

const LandingPage = () => {
    return (
        <div className="min-h-screen bg-gray-800 bg-center bg-cover flex flex-col items-center justify-center p-10"
            style={{ backgroundImage: 'url(images/bg.png)' }}>
            <ul>
                {/* <li className="mb-5">
          <Link to="/page1" className="text-blue-400 hover:text-blue-600 text-2xl font-bold">Go to Page 1</Link>
        </li> */}
                <li className="mb-5">
                    <Link to="/roleplay" className="text-blue-200 hover:text-white text-4xl font-bold">ロールプレイの対戦結果ビューワー</Link>
                </li>
                <li className="mb-5">
                    <Link to="/erp" className="text-blue-200 hover:text-white text-4xl font-bold">ERPの評価結果ビューワー（18歳以上）</Link>
                </li>
            </ul>

            <div className="mt-10">
                <a href="https://github.com/oshizo/japanese-llm-roleplay-benchmark" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} className="text-white text-4xl hover:text-gray-400" />
                </a>
            </div>
        </div>
    );
};

export default LandingPage;