import React from 'react';

const ErpResultDetail = ({ scoring_result }) => {
    const imageUrl = `/images/erp/${scoring_result.situation_id}.png`; // 画像のパスを指定

    return (
        <div className="rounded-lg overflow-hidden shadow-lg dark:bg-gray-700 p-4">
            <div className="grid grid-cols-2 gap-4">
                <div className="mr-4">
                    <img src={imageUrl} alt="Situation Image" className="" /> {/* 画像の表示部分 */}
                </div>
                <div className="">
                    <div>
                        <strong className="text-gray-400">Character:</strong> {scoring_result.character}
                    </div>
                    <div>
                        <strong className="text-gray-400">Context:</strong> {scoring_result.context}
                    </div>
                    <div>
                        <strong className="text-gray-400">{scoring_result.model_id}:</strong> {scoring_result.response}
                    </div>
                    <div>
                        <strong className="text-gray-400">Score:</strong> {scoring_result.score}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErpResultDetail;