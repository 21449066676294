import React, { useState, useEffect } from 'react';
import MatchDetail from '../components/MatchDetail';

const Page2 = () => {
  const [matchData, setMatchData] = useState([]);
  const [selectedSituationId, setSelectedSituationId] = useState("");
  const [selectedModelA, setSelectedModelA] = useState("");
  const [selectedModelB, setSelectedModelB] = useState("");
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [uniqueSituations, setUniqueSituations] = useState([]);
  const [uniqueModelAs, setUniqueModelAs] = useState([]);
  const [uniqueModelBs, setUniqueModelBs] = useState([]);

  const handleDropdownChange = (newSituationId, newModelA, newModelB) => {
    let foundMatch = matchData.find(match => (
      match.situation_id === newSituationId &&
      match.model_id_A === newModelA &&
      match.model_id_B === newModelB
    ));

    if (!foundMatch) {
      foundMatch = matchData.find(match => (
        match.situation_id === newSituationId &&
        match.model_id_A === newModelB &&
        match.model_id_B === newModelA
      ));
    }

    setSelectedMatch(foundMatch);
  };

  useEffect(() => {
    fetch('/match_results.jsonl')
      .then((response) => response.text())
      .then((data) => {
        const lines = data.trim().split('\n');
        const parsedData = lines.map((line) => JSON.parse(line));
        setMatchData(parsedData);
        setSelectedSituationId(parsedData[0].situation_id);
        setSelectedModelA(parsedData[0].model_id_A);
        setSelectedModelB(parsedData[0].model_id_B);
        const situations = [...new Set(parsedData.map(item => item.situation_id))];
        // 数値としてソートして、再び文字列に変換する
        const sortedSituations = situations.sort((a, b) => parseInt(a) - parseInt(b));

        setUniqueSituations(sortedSituations);
        const modelAs = [...new Set(parsedData.map(item => item.model_id_A))];
        const modelBs = [...new Set(parsedData.map(item => item.model_id_B))];

        setUniqueSituations(situations);
        setUniqueModelAs(modelAs);
        setUniqueModelBs(modelBs);


      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // フェッチしたデータに基づいて、デフォルトの値を設定する
  useEffect(() => {
    if (matchData.length > 0) {
      handleDropdownChange(uniqueSituations[0], uniqueModelAs[0], uniqueModelBs[0]);
    }
  }, [matchData, uniqueSituations, uniqueModelAs, uniqueModelBs]);

  return (
    <div className="dark:bg-gray-800 dark:text-white min-h-screen p-6">
      <div className="mb-4 flex space-x-4">

        <div className="flex flex-col">
          <label className="text-gray-400 mb-1">situation_id</label>
          <select value={selectedSituationId} onChange={(e) => {
            setSelectedSituationId(e.target.value);
            handleDropdownChange(e.target.value, selectedModelA, selectedModelB);
          }} className="dark:bg-gray-700 p-2 border dark:border-gray-600 rounded">
            {uniqueSituations.map(id => (
              <option key={id} value={id}>{id}</option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-gray-400 mb-1">model_id</label>
          <select value={selectedModelA} onChange={(e) => {
            setSelectedModelA(e.target.value);
            handleDropdownChange(selectedSituationId, e.target.value, selectedModelB);
          }} className="dark:bg-gray-700 p-2 border dark:border-gray-600 rounded">
            {uniqueModelAs.map(id => (
              <option key={id} value={id}>{id}</option>
            ))}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-gray-400 mb-1">model_id</label>
          <select value={selectedModelB} onChange={(e) => {
            setSelectedModelB(e.target.value);
            handleDropdownChange(selectedSituationId, selectedModelA, e.target.value);
          }} className="dark:bg-gray-700 p-2 border dark:border-gray-600 rounded">
            {uniqueModelBs.map(id => (
              <option key={id} value={id}>{id}</option>
            ))}
          </select>
        </div>

      </div>

      {selectedMatch ? <MatchDetail match={selectedMatch} /> : <p className="text-gray-400">マッチが見つかりません</p>}
    </div>
  );
};

export default Page2;
