import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
// import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import LandingPage from './pages/LandingPage';

const App = () => {
    useEffect(() => {
        document.title = "Furoriented";
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                {/* <Route path="/page1" element={<Page1 />} /> */}
                <Route path="/roleplay" element={<Page2 />} />
                <Route path="/erp" element={<Page4 />} />
                {/* 今後追加するページ */}
            </Routes>
        </Router>
    );
};

export default App;